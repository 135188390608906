import { Injectable } from '@angular/core';
import { Router, ActivatedRoute }                               from '@angular/router';
import jsonVersiones from '../../assets/json/versiones.json';

@Injectable({
  providedIn: 'root'
})
export class DataGlobalService {
  declaracion;
  public modal                        = false;

  private PROD: any                   = window.location.protocol +'//'+window.location.hostname;
  private local_prod: string          = "https://repositorio.uc.cl";
  private local_lib: string           = "https://repositorio.lib.cloud.uc.cl";
  private local_des: string           = "https://repositorio.des.cloud.uc.cl";

  // DECLARAR ENTORNO
  public dominio: string             = this.PROD;
  // ****

  
  /* private dominio: string; */
  public php: string                  = '/assets/php';
  public php_img: string              = "/server";
  public php_download: string         = "/dspace";

  public apiDiscovery: string         = this.dominio + this.php + "/discovery.php?";
  public urlImg:string                = this.dominio + this.php_img + "/api/core/bitstreams"; 
  public apilDownload:string          = this.dominio + this.php_download + "/bitstreams";
  public apiEmail: string             = this.dominio + this.php + "/mail-contacto/envio.php?";
  public apiCatVillarrica:string      = this.dominio + this.php + "/cat-villarrica.php";
  public apiCatOds:string             = this.dominio + this.php + "/categorias-ods.php";
  public apiCategorias:string         = this.dominio + this.php + "/categorias.php";
  public apiCantidadTesis:string      = this.dominio + this.php + "/cantidad-tesis.php";
  public apiCantidadArticulos:string  = this.dominio + this.php + "/cantidad-articulos.php";
  public apiCantidadLibros:string     = this.dominio + this.php + "/cantidad-libros.php";
  public apiSusNewsletter:string      = this.dominio + this.php + "/api-mailchimp/suscribir-newsletter.php?email=";
  public apiMailchimp:string          = this.dominio + this.php +'/api-mailchimp/landing-page.php';
  public apiUltimosAgregados:string   = this.dominio + this.php + "/ultimos-agregados.php?";
  public apiUploadCreacionArtistica:string  = this.dominio + this.php + "/autoarchivo/creacionArtistica.php";
  public apiUploadPublicaciones:string      = this.dominio + this.php +  "/autoarchivo/subirArchivo.php";
  public apiUploadPatentes:string           = this.dominio + this.php + "/autoarchivo/patentes.php";
  public apiUploadInvestigacion:string      = this.dominio + this.php + "/autoarchivo/uploadInvestigacion.php";
  public apiFicha:string                    = this.dominio + this.php + "/ficha.php?";
  public apiAutoarchivo:string              = this.dominio + this.php + "/autoarchivo/";
 
  public jsonVersiones:any = jsonVersiones;
  public versionPortal:any = [];
  loggedIn: any = false;

  constructor(
    private router: Router
  ) { }


  public login(rutaredirect){
    if (!this.loggedIn && !localStorage.getItem('correo')) {
      if(this.dominio == this.PROD){
        window.location.href='assets/php/cas-log/cas-login.php?ruta='+ rutaredirect; 
      }else{
        localStorage.setItem('usuario', 'RODRIGO ALEJANDRO PANTOJA FLORES'); /* DARIANA HERCILIA GÓMEZ REYES */
        localStorage.setItem('correo', 'rodrigo.pantoja@uc.cl');   /* dariana.gomez@uc.cl */
        console.log("se loguea en CAS y redirecciona a" + rutaredirect)
        this.router.navigate([rutaredirect]);
      }
    } else if (this.loggedIn || localStorage.getItem('correo')) {
      this.router.navigate([rutaredirect]);
    }
  }
  public logoutCas(){
    localStorage.removeItem('usuario');
    localStorage.removeItem('correo');
    localStorage.removeItem('unidad');
    window.location.href='assets/php/cas-log/logout-cas.php';
  }

  public versionesPortal(){
    this.versionPortal = jsonVersiones;
    for(var i = 0; i < jsonVersiones.length; i++){
      this.versionPortal[i].version = this.jsonVersiones[i].numero_version + "." + this.jsonVersiones[i].numero_mejora_version + "." + this.jsonVersiones[i].numero_item_mejora + "-" + this.jsonVersiones[i].numero_rama
    }
  }
}
